import { API } from "aws-amplify";
import { updateToolHolderId } from "api/mutations";
import { getToolHolder } from "api/queries";
import { returnToolTransaction } from "./transactions";

export default async function checkInTool(staffId, toolId) {
  console.log("Check in tool: ", staffId, toolId);
  const eidRequest = {
    query: getToolHolder,
    variables: {
      id: toolId,
    },
  };
  console.log("Check in request: ", eidRequest);
  let response = await API.graphql(eidRequest);
  console.log("Check in response: ", response);
  let status = response.data.getMachine.status;

  if (status === "CHECKEDOUT" || status === "ACTIVE") {
    let eid = response.data.getMachine.currentHolderId;
    const request = {
      query: updateToolHolderId,
      variables: {
        input: {
          id: toolId,
          currentHolderId: "tiw",
          status: "CHECKEDIN",
        },
      },
    };

    response = await API.graphql(request);
    returnToolTransaction(eid, staffId, toolId);
  } else {
    throw new Error("Tool is already checked in");
  }
  return response.data.updateTool;
}
