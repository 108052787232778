export const getAllMembers = /* GraphQL */ `
  query GetAllMembers(
    $eid: String
    $filter: ModelMemberFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listMembers(
      eid: $eid
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        eid
        emailAddress
        firstName
        lastName
        fabmanId
        canvasId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const getMemberProfile = /* GraphQL */ `
  query GetMemberProfile($eid: String!) {
    getMember(eid: $eid) {
      eid
      firstName
      lastName
      emailAddress
      trainings {
        items {
          id
          canvasId
          fabmanId
          training {
            name
          }
        }
      }
    }
  }
`;

// These should be removed, filling old dependencies during development.
export const getMemberTransactions = /* GraphQL */ `
  query GetMemberTransactions($eid: ID!, $nextToken: String, $limit: Int) {
    getMember(eid: $eid) {
      transactionRecord(
        nextToken: $nextToken
        limit: $limit
        sortDirection: DESC
      ) {
        nextToken
        items {
          createdAt
          transactionType
          toolId
          printId
          staffMemberId
        }
      }
    }
  }
`;

export const listCheckedOutTools = /* GraphQL */ `
  query ListCheckedOutTools(
    $filter: ModelToolFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTools(filter: $filter, limit: $limit, nextToken: $nextToken) {
      nextToken
      items {
        id
        name
        description
        location
      }
    }
  }
`;

export const getToolHolder = /* GraphQL */ `
  query GetToolHolder($id: ID!) {
    getMachine(id: $id) {
      id
      currentHolderId
      status
    }
  }
`;

// Tool Queries
export const getToolStatus = /* GraphQL */ `
  query GetToolStatus($id: ID!) {
    getMachine(id: $id) {
      status
    }
  }
`;

export const listCheckedInMembers = /* GraphQL */ `
  query ListCheckedInMembers(
    $checkInStatus: Int!
    $workstationId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMemberFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMembersByStatus(
      checkInStatus: $checkInStatus
      workstationId: $workstationId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        eid
        firstName
        lastName
        checkInStatus
      }
      nextToken
    }
  }
`;

// Print Queries
export const getAllPrints = /* GraphQL */ `
  query GetAllPrints(
    $filter: ModelPrintFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPrints(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        memberId
        name
        printStatus
        createdAt
      }
      nextToken
    }
  }
`;

export const getAllMachines = /* GraphQL */ `
  query GetAllTools(
    $filter: ModelMachineFilterInput
    $nextToken: String
    $limit: Int
  ) {
    listMachines(nextToken: $nextToken, limit: $limit, filter: $filter) {
      items {
        id
        name
        currentHolderId
        machineGroup {
          name
        }
        status
        type
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const getToolIdByName = /* GraphQL */ `
  query ListMachinesByName(
    $name: String!
    $sortDirection: ModelSortDirection
    $filter: ModelMachineFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMachinesByName(
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
      }
      nextToken
    }
  }
`;

export const getToolHistory = /* GraphQL */ `
  query GetToolHistory($id: ID!, $limit: Int) {
    getMachine(id: $id) {
      id
      currentHolderId
      name
      transactionRecord(limit: $limit, sortDirection: DESC) {
        items {
          id
          createdAt
          transactionType
          member {
            eid
            firstName
            lastName
          }
          staffMember {
            eid
            firstName
            lastName
          }
          transactionComment
        }
        nextToken
      }
    }
  }
`;

export const userSync = /* GraphQL */ `
  query UserSync($eid: String!) {
    userSync(eid: $eid)
  }
`;
