/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * File: MemberTrainings.js
 * Description: Handles the training section for the Member
 * Details dialog popup. Trainings are displayed as cards
 * with the levels on a slider.
 *
 * Trainings are only editable by people who are part of the
 * staff group on Cognito. They are also viewable to the member
 * whenever they look at their profile.
 *
 * written for Texas Inventionworks at UT Austin
 * authors: Davin Lawrence
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import {
  Paper,
  Box,
  Grid,
  Card,
  Slider,
  Typography,
  Divider,
} from "@material-ui/core";

import Header from "../../Header";

import { useContext } from "react";
import AppContext from "../../../helpers/AppContext";
import PaperAccent from "../../PaperAccent";

// Manage member Trainings. Renders as a groups of cards
export default function MemberTrainings(props) {
  const { trainings, setTrainings, dirty, setDirty, classes } = props;
  const { userPerms } = useContext(AppContext);

  const levelKey = [
    "Untrained",
    "Digital Training",
    "Fully Trained",
    "Can Train",
    "Staff Lead",
    "Professional",
  ];

  const handleChange = (idx, val) => {
    const authorized = userPerms.staff || userPerms.admin || userPerms.faculty;
    if (authorized && val !== trainings[idx].level) {
      let newTrainings = trainings.map((training) => {
        return training;
      });
      newTrainings[idx].level = val;
      setTrainings(newTrainings);
      dirty.trainings = true;
      setDirty(dirty);
    }
  };

  return (
    <Paper>
      <PaperAccent />
      <Header variant="h5" text="Trainings" />
      <Box ml={2} mr={2} pb={2}>
        <Grid container spacing={2} className={classes.trainingGrid}>
          {trainings.map((training, idx) => {
            return (
              <Grid item xs={12} sm={6} md={3}>
                <Card variant="outlined">
                  <Box p={2} pt={0}>
                    <Header
                      variant="h6"
                      pb={0}
                      pl={0}
                      text={training.training.name}
                    />
                    <Divider p={2} />
                    <Typography key={idx} type="body2" gutterBottom>
                      {levelKey[training.level]}
                    </Typography>
                    <Box pt={4} pr={2} pl={2}>
                      <Slider
                        defaultValue={0}
                        value={training.level}
                        step={1}
                        min={0}
                        max={5}
                        marks
                        valueLabelDisplay="on"
                        onChange={(event, val) => handleChange(idx, val)}
                      />
                    </Box>
                  </Box>
                </Card>
              </Grid>
            );
          })}
        </Grid>
      </Box>
    </Paper>
  );
}
