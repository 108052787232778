/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * File: SignIn.js
 * Description: Simple helper function to call Auth.signIn()
 *
 * written for Texas Inventionworks at UT Austin
 * authors: Davin Lawrence
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { Auth } from "aws-amplify";

async function signIn(credentials) {
  const { user, pass } = credentials;
  let creds = await Auth.signIn(user, pass);
  return creds;
}

export default signIn;
