/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * File: LoginDialog.js
 * Description: Popup dialog for signing in. Designed to be
 * called by any login button. Prompts the user to log in
 * using either their EID or login/pass for the staff
 * TIW account.
 *
 * authors: Davin Lawrence
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { AppContext } from "../helpers";
import { useContext } from "react";
import { Route, Redirect } from "react-router-dom";

export default function PrivateRoute(props) {
  const { authenticated } = useContext(AppContext);
  if (authenticated) {
    return <Route {...props} />;
  } else {
    return <Redirect to="/" />;
  }
}
