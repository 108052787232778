/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * File: MemberSearch.js
 * Description: Handles the Member Search container of the
 * staff user page. This is a simple search to look up EIDs
 * and will only work when the exact EID is present in the
 * database.
 *
 * Future revisions can perform a fuzzier search and display
 * a list of possible matches for the user to choose from
 * before continuing to the Member Dialog.
 *
 * written for Texas Inventionworks at UT Austin
 * authors: Davin Lawrence
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { useState } from "react";
import { Paper, TextField, Button } from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";

import { Header, PaperAccent } from "components";

const useStyles = makeStyles(() => ({
  memberSearch: {
    height: "15em",
    maxHeight: "15em",
    justifyContent: "center",
  },
  form: {
    padding: 0,
    paddingLeft: 20,
    paddingRight: 20,
    justifyContent: "center",
    "& > *": {
      width: "100%",
    },
  },
}));

export default function MemberSearch(props) {
  const classes = useStyles();
  const [eidField, setEidField] = useState("");
  const { setSearchMember, setMemberDialog } = props;

  const handleSubmit = (e) => {
    e.preventDefault();
    setSearchMember(eidField.toLowerCase().trim());
    setEidField("");
    setMemberDialog(true);
  };

  return (
    <div>
      <Paper className={classes.memberSearch}>
        <PaperAccent />
        <Header variant="h5" text="EID Lookup" />
        <form
          noValidate
          autoComplete="off"
          className={classes.form}
          onSubmit={handleSubmit}
        >
          <TextField
            id="member-eid"
            label="Member EID"
            value={eidField}
            margin="dense"
            size="small"
            variant="outlined"
            onInput={(e) => setEidField(e.target.value)}
          />
          <br />
          <Button
            type="submit"
            size="large"
            variant="contained"
            color="primary"
          >
            Search
          </Button>
        </form>
      </Paper>
    </div>
  );
}
