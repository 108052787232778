/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * File: memberCheckIn.js
 * Description: Contains helper functions for the various kinds
 * of transactions that can be generated
 *
 * written for Texas Inventionworks at UT Austin
 * authors: Davin Lawrence
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { API } from "aws-amplify";
import { createTransaction } from "api/mutations";

const modelRequest = {
  query: createTransaction,
  variables: {
    input: {
      staffMemberId: "",
      transactionStatus: "COMPLETE",
      transactionType: "",
      transactionComment: "",
    },
  },
};

async function createNewTransaction(request) {
  try {
    let response = await API.graphql(request);
    return response.data.createTransaction;
  } catch (err) {
    console.log("Failed to create transaction: ", err);
  }
}

export async function checkInTransaction(eid, staffId, reason) {
  let request = { ...modelRequest };
  request.variables.input.memberId = eid;
  request.variables.input.transactionType = "MEMBERCHECKIN";
  request.variables.input.transactionComment = reason;
  if (staffId) {
    request.variables.input.staffMemberId = staffId;
  }

  return await createNewTransaction(request);
}

export async function checkOutTransaction(eid, staffId) {
  let request = { ...modelRequest };
  request.variables.input.memberId = eid;
  request.variables.input.transactionType = "MEMBERCHECKOUT";
  if (staffId) {
    request.variables.input.staffMemberId = staffId;
  }

  return await createNewTransaction(request);
}

export async function checkOutToolTransaction(eid, staffId, toolId) {
  let request = { ...modelRequest };
  request.variables.input.memberId = eid;
  request.variables.input.staffMemberId = staffId;
  request.variables.input.toolId = toolId;
  request.variables.input.transactionType = "TOOLCHECKOUT";

  return await createNewTransaction(request);
}

export async function returnToolTransaction(eid, staffId, toolId) {
  let request = { ...modelRequest };
  request.variables.input.memberId = eid;
  request.variables.input.staffMemberId = staffId;
  request.variables.input.toolId = toolId;
  request.variables.input.transactionType = "TOOLCHECKIN";

  return await createNewTransaction(request);
}

export async function updatePrintTransaction(eid, staffId, printId) {
  let request = { ...modelRequest };
  request.variables.input.memberId = eid;
  request.variables.input.staffMemberId = staffId;
  request.variables.input.printId = printId;
  request.variables.input.transactionType = "PRINTUPDATE";

  return await createNewTransaction(request);
}

export async function createMachineGroupTransaction(
  staffId,
  machineGroupId,
  machineGroup
) {
  let request = { ...modelRequest };
  request.variables.input.staffMemberId = staffId;
  request.variables.input.transactionType = "ADMINISTRATION";
  request.variables.input.transactionComment = `Created Group ${machineGroup}`;
  request.variables.input.machineGroupId = machineGroupId;

  return await createNewTransaction(request);
}

export async function updateMachineGroupTransaction(
  staffId,
  machineGroupId,
  machineGroup
) {
  let request = { ...modelRequest };
  request.variables.input.staffMemberId = staffId;
  request.variables.input.transactionType = "ADMINISTRATION";
  request.variables.input.transactionComment = `Updated Group ${machineGroup}`;
  request.variables.input.machineGroupId = machineGroupId;

  return await createNewTransaction(request);
}

export async function createMachineTransaction(staffId, machineId, machine) {
  let request = { ...modelRequest };
  request.variables.input.staffMemberId = staffId;
  request.variables.input.transactionType = "ADMINISTRATION";
  request.variables.input.transactionComment = `Created Machine ${machine}`;
  request.variables.input.machineId = machineId;

  return await createNewTransaction(request);
}

export async function updateMachineTransaction(staffId, machineId, machine) {
  let request = { ...modelRequest };
  request.variables.input.staffMemberId = staffId;
  request.variables.input.transactionType = "ADMINISTRATION";
  request.variables.input.transactionComment = `Updated Machine ${machine}`;
  request.variables.input.machineId = machineId;

  return await createNewTransaction(request);
}

export async function createAreaTransaction(staffId, areaId, name) {
  let request = { ...modelRequest };
  request.variables.input.staffMemberId = staffId;
  request.variables.input.transactionType = "ADMINISTRATION";
  request.variables.input.transactionComment = `Created Area ${name}`;
  request.variables.input.areaId = areaId;

  return await createNewTransaction(request);
}

export async function updateAreaTransaction(staffId, areaId, name) {
  let request = { ...modelRequest };
  request.variables.input.staffMemberId = staffId;
  request.variables.input.transactionType = "ADMINISTRATION";
  request.variables.input.transactionComment = `Updated Machine ${name}`;
  request.variables.input.areaId = areaId;

  return await createNewTransaction(request);
}
