/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * File: StaffTools.js
 * Route: https://texasinventionworks.com/staff/tools
 * Description: Page to allow staff to view and manage tools.
 * Staff will be able to add new tools as they come available
 *
 * written for Texas Inventionworks at UT Austin
 * authors: Davin Lawrence
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import {
  Grid,
  makeStyles,
  Paper,
  Box,
  TextField,
  Button,
  ButtonGroup,
  IconButton,
} from "@material-ui/core";

import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from "@material-ui/icons/Clear";

import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarFilterButton,
} from "@material-ui/data-grid";
import { API } from "aws-amplify";
import { useEffect, useState } from "react";
import { getAllMachines } from "api/queries";
import {
  PageHeader,
  PaperAccent,
  ToolHistory,
  NewToolDialog,
} from "components";
import { checkInTools, formatTime, escapeRegExp } from "helpers";

const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    height: "50em",
  },
  box: {
    display: "flex",
    width: "100%",
  },
  toolbar: {
    padding: theme.spacing(1),
    justifyContent: "space-between",
    display: "flex",
    alignItems: "flex-start",
  },
  textField: {
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
    margin: theme.spacing(1),
  },
  buttons: {
    margin: theme.spacing(1),
  },
}));

async function listTools(setTools) {
  let tools = [];
  let response, nextPage;
  let request = {
    query: getAllMachines,
    variables: {
      limit: 500,
      nextToken: null,
    },
  };

  do {
    response = await API.graphql(request);
    tools.push(...response.data.listMachines.items);
    nextPage = response.data.listMachines.nextToken;
    request.variables.nextToken = nextPage;
  } while (nextPage != null);
  return tools;
}

function DataGridToolBar(props) {
  const {
    classes,
    handleCheckIn,
    searchText,
    handleHistory,
    selection,
    onSearchChange,
    clearSearch,
  } = props;

  return (
    <GridToolbarContainer className={classes.toolbar}>
      <div className={classes.buttons}>
        <ButtonGroup color="primary" variant="contained" size="small">
          <Button disabled={selection.length === 0} onClick={handleCheckIn}>
            Check In
          </Button>
          <Button disabled={selection.length !== 1} onClick={handleHistory}>
            History
          </Button>
        </ButtonGroup>
        <GridToolbarFilterButton />
        <GridToolbarColumnsButton />
      </div>
      <TextField
        className={classes.textField}
        variant="standard"
        placeholder="Search..."
        value={searchText}
        autoFocus
        onChange={onSearchChange}
        InputProps={{
          startAdornment: <SearchIcon fontSize="small" />,
          endAdornment: (
            <IconButton
              title="Clear"
              size="small"
              style={{ visibility: searchText ? "visible" : "hidden" }}
              onClick={clearSearch}
            >
              <ClearIcon fontSize="small" />
            </IconButton>
          ),
        }}
      />
    </GridToolbarContainer>
  );
}

export default function StaffTools() {
  const [loading, setLoading] = useState(true);
  const [tools, setTools] = useState([]);
  const [rows, setRows] = useState(tools);
  const [historyDialog, setHistoryDialog] = useState(false);
  const [newToolDialog, setNewToolDialog] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [selection, setSelection] = useState([]);
  const [lookupTool, setLookupTool] = useState("");
  const classes = useStyles();

  useEffect(() => {
    listTools(setTools)
      .then((result) => {
        for (var i = 0; i < result.length; i++) {
          result[i].group = result[i].machineGroup.name;
        }
        setTools(result);
        setRows(result);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const columns = [
    // { field: "id", headerName: "Tool Id", width: 150, flex: 1 },
    { field: "name", headerName: "Name", width: 165, flex: 1 },
    { field: "status", headerName: "Status", width: 150, flex: 1 },
    { field: "group", headerName: "Group", width: 120, flex: 1 },
    // { field: "type", headerName: "Type", width: 150, flex: 1 },
    {
      field: "currentHolderId",
      headerName: "Current Holder",
      width: 250,
      flex: 1,
    },
    {
      field: "updatedAt",
      headerName: "Recent Activity",
      width: 175,
      flex: 1,
      valueFormatter: (params) => {
        const formatted = formatTime(params);
        return `${formatted}`;
      },
    },
  ];

  const requestSearch = (searchValue) => {
    setSearchText(searchValue);
    const searchRegex = new RegExp(escapeRegExp(searchValue), "i");
    const filteredRows = tools.filter((row) => {
      return Object.keys(row).some((field) => {
        if (row[field] != null) {
          return searchRegex.test(row[field].toString());
        } else {
          return null;
        }
      });
    });
    setRows(filteredRows);
  };

  const handleHistory = () => {
    setHistoryDialog(true);
  };

  const handleHistoryClose = () => {
    setHistoryDialog(false);
  };

  const handleAddTool = () => {
    setNewToolDialog(true);
  };

  const handleAddToolClose = () => {
    setNewToolDialog(false);
  };

  const handleCheckIn = () => {
    checkInTools(selection).then((result) => {
      if (result) {
        setLoading(true);
        setTools([]);
        listTools(setTools).then((result) => {
          setLoading(false);
          setTools(result);
          setRows(result);
          requestSearch(searchText);
          setSelection([]);
        });
      }
    });
  };

  return (
    <Grid container spacing={2}>
      <PageHeader text="Tools" />
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <PaperAccent loading={loading} />
          <Box p={2} className={classes.box}>
            <DataGrid
              checkboxSelection
              rows={rows}
              pageSize={50}
              columns={columns}
              loading={loading}
              onSelectionModelChange={(newSelection) => {
                setSelection(newSelection);
                if (newSelection.length === 1) {
                  setLookupTool(newSelection[0]);
                }
              }}
              components={{ Toolbar: DataGridToolBar }}
              componentsProps={{
                toolbar: {
                  classes: classes,
                  selection: selection,
                  handleCheckIn: handleCheckIn,
                  handleHistory: handleHistory,
                  handleAddTool: handleAddTool,
                  searchText: searchText,
                  onSearchChange: (e) => requestSearch(e.target.value),
                  clearSearch: () => requestSearch(""),
                },
              }}
            />
          </Box>
        </Paper>
      </Grid>
      <ToolHistory
        toolId={lookupTool}
        open={historyDialog}
        onClose={handleHistoryClose}
      />
      <NewToolDialog open={newToolDialog} onClose={handleAddToolClose} />
    </Grid>
  );
}
