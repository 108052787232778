/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * File: StaffNav.js
 * Description: This file contains the code for the Staff
 * navigation options in the Menu Drawer.
 *
 * written for Texas Inventionworks at UT Austin
 * authors: Davin Lawrence
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React from "react";
import { ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import { Link } from "react-router-dom";
import DashboardOutlinedIcon from "@material-ui/icons/DashboardOutlined";
import BuildOutlinedIcon from "@material-ui/icons/BuildOutlined";
import PeopleAltOutlinedIcon from "@material-ui/icons/PeopleAltOutlined";

// The possible routes that staff can reach.
const staffRoutes = [
  {
    title: "Staff Dashboard",
    key: "nav-staff-dashboard",
    icon: <DashboardOutlinedIcon fontSize="large" color="secondary" />,
    path: "/dashboard",
  },
  {
    title: "Manage Members",
    key: "nav-staff-members",
    icon: <PeopleAltOutlinedIcon fontSize="large" color="secondary" />,
    path: "/members",
  },
  // {
  //     title: "Manage Prints",
  //     key: "nav-staff-prints",
  //     icon: <PrintOutlinedIcon fontSize='large' color="secondary" />,
  //     path: "/staff/prints",
  // },
  {
    title: "Manage Tools",
    key: "nav-staff-tools",
    icon: <BuildOutlinedIcon fontSize="large" color="secondary" />,
    path: "/tools",
  },
];

export default function StaffNav(props) {
  const { classes, drawerOpen, toggleDrawer, current } = props;

  return (
    <div id="staff-nav">
      {staffRoutes.map((route) => {
        return (
          <div key={route.key}>
            <Link to={route.path} className={classes.navLink}>
              <ListItem
                button
                key={route.key}
                onClick={drawerOpen ? toggleDrawer : null}
                selected={current === route.path}
              >
                <ListItemIcon className={classes.icon} color="secondary">
                  {route.icon}
                </ListItemIcon>
                <ListItemText primary={route.title} />
              </ListItem>
            </Link>
          </div>
        );
      })}
    </div>
  );
}
