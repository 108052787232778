/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * File: CheckedInMembers.js
 * Description: Manages the Checked In Members section of the
 * staff dashboard. Checked in members are displayed as a list
 * of Cards. Clicking on the more details button loads the
 * MemberDialog component onto the screen.
 *
 * written for Texas Inventionworks at UT Austin
 * authors: Davin Lawrence
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { useState, useEffect, useContext } from "react";
import PaperAccent from "components/PaperAccent";
import {
  Paper,
  Grid,
  Card,
  Box,
  Typography,
  Button,
  ButtonGroup,
} from "@material-ui/core";
import { API } from "aws-amplify";
import { listCheckedInMembers } from "api/queries";
import { makeStyles } from "@material-ui/core/styles";

import { Header, UserAvatar } from "components";
import { onCheckInStatusChange } from "api/subscriptions";
import memberCheckOut from "helpers/memberCheckOut";
import AppContext from "helpers/AppContext";

const useStyles = makeStyles((theme) => ({
  checkedInMembers: {
    height: "60em",
    maxHeight: "60em",
    // overflow: 'scroll'
  },
  memberCard: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    height: "12em",
    width: "100%",
    paddingTop: 20,
  },
  memberBox: {
    display: "flex",
    overflow: "auto",
    height: "25em",
    justifyContent: "center",
  },
  memberGrid: {
    width: "95%",
    paddingLeft: "2.5%",
    paddingRight: "2.5%",
    // overflow: 'scroll'
  },
  detailsButton: {
    marginBottom: "auto",
  },
  userSummary: {
    flexGrow: 1,
    justifyContent: "center",
  },
  avatar: {
    marginLeft: "auto",
    marginRight: "auto",
  },
  title: {
    display: "flex",
    justifyContent: "space-between",
  },
  checkOut: {
    height: "3em",
    marginTop: theme.spacing(2),
  },
}));

async function getCheckedInMembers() {
  let checkedInMembers = [];
  let response, nextPage;
  let query = listCheckedInMembers;
  let request = {
    query: query,
    variables: {
      checkInStatus: 1,
      limit: 500,
      nextToken: null,
    },
  };
  do {
    response = await API.graphql(request);
    checkedInMembers.push(...response.data.listMembersByStatus.items);
    nextPage = response.data.listMembersByStatus.nextToken;
    request.variables.nextToken = nextPage;
  } while (nextPage != null);

  return checkedInMembers;
}

export default function CheckedInMembers(props) {
  const [loading, setLoading] = useState(true);
  const [members, setMembers] = useState(true);
  const { setErrState } = useContext(AppContext);
  const classes = useStyles();
  const { setSearchMember, setMemberDialog } = props;

  const subscription = {
    query: onCheckInStatusChange,
  };

  useEffect(() => {
    // subscribe to new Checked In Members
    let checkedInSubscription = API.graphql(subscription).subscribe({
      next: ({ provider, value }) => {
        getCheckedInMembers().then((result) => {
          setMembers(result);
        });
      },
      error: (error) => console.log(error),
    });
    // list all currently checked in members.
    getCheckedInMembers().then((result) => {
      setMembers(result);
      setLoading(false);
    });

    return function cleanup() {
      checkedInSubscription.unsubscribe();
    };
  }, []);

  const handleMemberClick = (eid) => {
    setSearchMember(eid);
    setMemberDialog(true);
  };

  const handleCheckout = (member) => {
    try {
      memberCheckOut(member, "tiw"); //TODO: switch over to active logged in staff
    } catch (err) {
      console.log(err);
      setErrState({
        severity: "warning",
        open: true,
        text: "Dashboard is out of sync. Reload the page. If this error persists, contact portal maintenance team.",
      });
    }
  };

  const clearCheckedIn = () => {
    for (let i = 0; i < members.length; i++) {
      memberCheckOut(members[i], "tiw"); //TODO: switch over to active logged in staff
    }
  };

  return (
    <Paper className={classes.checkedInMembers}>
      <PaperAccent loading={loading} />
      <Box className={classes.title} pr={11}>
        <Header variant="h5" text="Checked In Members" />
        <Button
          color="primary"
          variant="contained"
          className={classes.checkOut}
          onClick={() => clearCheckedIn()}
        >
          Check Out All
        </Button>
      </Box>
      <Box className={classes.memberBox}>
        <Grid container spacing={2} className={classes.memberGrid}>
          {loading
            ? null
            : members.map((member) => {
                return (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={3}
                    key={`checkedInMember-${member.eid}`}
                  >
                    <div align="center">
                      <Card variant="outlined" className={classes.memberCard}>
                        <Box className={classes.avatar}>
                          <UserAvatar member={member} />
                        </Box>
                        <Box className={classes.userSummary}>
                          <Box p={1} pb={0}>
                            <Typography noWrap variant="h5">
                              {member.firstName} {member.lastName}
                            </Typography>
                          </Box>
                          {/* <Header noWrap variant='h6' pb={0} text={} /> */}
                          <Header variant="h6" pt={0} text={member.eid} />
                        </Box>
                        <Box className={classes.detailsButton} pb={2}>
                          <ButtonGroup
                            color="primary"
                            variant="contained"
                            size="small"
                            disabled={member.eid === "tiw"}
                          >
                            <Button
                              onClick={() => handleMemberClick(member.eid)}
                            >
                              Profile
                            </Button>
                            <Button onClick={() => handleCheckout(member)}>
                              CheckOut
                            </Button>
                          </ButtonGroup>
                        </Box>
                      </Card>
                    </div>
                  </Grid>
                );
              })}
        </Grid>
      </Box>
    </Paper>
  );
}
