import { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  makeStyles,
  DialogActions,
  Button,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  CircularProgress,
} from "@material-ui/core";

import { getToolHistory } from "api/queries";
import { API } from "@aws-amplify/api";
import { Header } from "components";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  root: {},
  table: {
    maxHeight: "35em",
    width: "95%",
    paddingLeft: "2.5%",
    paddingRight: "2.5%",
    overflow: "scroll",
  },
  tableHeader: {
    backgroundColor: theme.palette.background.paper,
  },
}));

async function toolHistory(toolId) {
  let request = {
    query: getToolHistory,
    variables: {
      id: toolId,
      limit: 100,
    },
  };

  let response = await API.graphql(request);
  return response.data.getMachine;
}

function ToolHistoryContent(props) {
  const { tool, classes } = props;
  if (tool.transactionRecord.items.length == 0) {
    console.log("No Tools Found");
    return (
      <>
        <DialogTitle>
          <Header variant="h4" p={0} text={tool.name} />
          <Header variant="h5" p={0} text={tool.id} />
        </DialogTitle>
        <DialogContent>
          <Paper>No history found.</Paper>
        </DialogContent>
      </>
    );
  }
  return (
    <div>
      <DialogTitle>
        <Header variant="h4" p={0} text={tool.name} />
        <Header variant="h5" p={0} text={tool.id} />
      </DialogTitle>
      <DialogContent>
        <Paper>
          <Header variant="h5" pt={0} text="History" />
          <TableContainer className={classes.table}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.tableHeader}>
                    Transaction
                  </TableCell>
                  <TableCell className={classes.tableHeader}>
                    Staff Member
                  </TableCell>
                  <TableCell className={classes.tableHeader}>
                    Staff EID
                  </TableCell>
                  <TableCell className={classes.tableHeader}>Member</TableCell>
                  <TableCell className={classes.tableHeader}>
                    Member EID
                  </TableCell>
                  <TableCell className={classes.tableHeader}>
                    Timestamp
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tool.transactionRecord.items.map((t) => {
                  let direction = t.transactionType === "TOOLCHECKIN";
                  return (
                    <TableRow key={t.id}>
                      <TableCell key={`transaction-${t.id}`}>
                        {direction ? "Return" : "Check Out"}
                      </TableCell>
                      <TableCell key={`staff-name-${t.id}`}>
                        {t.staffMember.firstName} {t.staffMember.lastName}
                      </TableCell>
                      <TableCell key={`staff-id-${t.id}`}>
                        {t.staffMember.eid}
                      </TableCell>
                      <TableCell key={`member-name-${t.id}`}>
                        {t.member ? t.member.firstName : "none"}
                        {t.member ? t.member.lastName : ""}
                      </TableCell>
                      <TableCell key={`member-id-${t.id}`}>
                        {t.member ? t.member.eid : ""}
                      </TableCell>
                      <TableCell key={`timestamp-${t.id}`}>
                        {moment(t.createdAt).format(
                          "ddd, MMM Do YYYY, h:mm:ss a"
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </DialogContent>
    </div>
  );
}

export default function ToolHistory(props) {
  const { toolId, open, onClose } = props;
  const [toolData, setToolData] = useState({});
  const [loading, setLoading] = useState(true);
  const classes = useStyles();

  useEffect(() => {
    if (toolId) {
      toolHistory(toolId)
        .then((result) => {
          console.log(result);
          setToolData(result);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [toolId]);

  const handleClose = () => {
    onClose();
  };
  return (
    <Dialog
      className={classes.root}
      maxWidth="md"
      fullWidth
      onClose={handleClose}
      open={open}
      scroll="paper"
    >
      {" "}
      {loading ? (
        <CircularProgress size={64} />
      ) : (
        <ToolHistoryContent tool={toolData} classes={classes} />
      )}
      <DialogActions>
        <Button variant="contained" color="primary" onClick={handleClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
