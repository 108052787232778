/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * File: signOut.js
 * Description: Simple helper function to call Auth.signOut()
 *
 * written for Texas Inventionworks at UT Austin
 * authors: Davin Lawrence
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { Auth } from "aws-amplify";

async function signOut() {
  return await Auth.signOut();
}

export default signOut;
