/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * File: QuickStats.js
 * Description: Created to display quick stats about the space.
 * Possible stats include, the number of active prints, the
 * number of members checked in, or days without accident.
 *
 * written for Texas Inventionworks at UT Austin
 * authors: Davin Lawrence
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { Paper } from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";

import { PaperAccent, Header } from "components";

const useStyles = makeStyles(() => ({
  quickStats: {
    height: "15em",
    maxHeight: "15em",
  },
}));

export default function QuickStats() {
  const classes = useStyles();

  return (
    <div>
      <Paper className={classes.quickStats}>
        <PaperAccent />
        <Header variant="h5" text="Quick Stats" />
      </Paper>
    </div>
  );
}
