import "./App.css";

import { Container, CssBaseline } from "@material-ui/core";
import { Amplify, Auth } from "aws-amplify";
import awsExports from "./aws-exports";

import theme from "./theme";
// Import fonts
import "@fontsource/barlow-condensed/600.css";
import "@fontsource/roboto";

import { makeStyles } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/core/styles";
import { useState, useEffect } from "react";
import { Switch, Route, BrowserRouter as Router } from "react-router-dom";

// local imports
import AppContext from "./helpers/AppContext";
import Login from "./components/Login";
import { NavBar, MenuDrawer, MessageBar, PrivateRoute } from "./components";
import { StaffMembers, StaffDashboard, StaffTools } from "./pages";

Amplify.configure(awsExports);

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  main: {
    // display: 'flex',
    width: "100vw",
    paddingTop: 75,
    marginBottom: 25,
    flexGrow: 1,
    minHeight: "100vh",
    alignContent: "flex-start",
  },
  pageLoading: {
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

function App() {
  const classes = useStyles();
  const [authenticated, setAuthenticated] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [errState, setErrState] = useState({
    severity: "error",
    open: false,
    text: "",
  });

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen); // toggle drawer state
  };

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((result) => {
        setAuthenticated(true);
      })
      .catch((err) => {
        setAuthenticated(false);
      });
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AppContext.Provider
        value={{
          authenticated,
          toggleDrawer,
          setAuthenticated,
          setErrState,
        }}
      >
        <div className={classes.root}>
          <MessageBar state={errState} setState={setErrState} />
          <Router>
            {authenticated ? (
              <>
                <NavBar />
                <MenuDrawer />
              </>
            ) : null}
            <Container maxwidth="lg" className={classes.main}>
              <Switch>
                <Route exact path="/" component={Login} />
                <PrivateRoute exact path="/members" component={StaffMembers} />
                <PrivateRoute exact path="/tools" component={StaffTools} />
                <PrivateRoute
                  exact
                  path="/dashboard"
                  component={StaffDashboard}
                />
              </Switch>
            </Container>
          </Router>
        </div>
      </AppContext.Provider>
    </ThemeProvider>
  );
}

export default App;
