export const updateToolHolderId = /* GraphQL */ `
  mutation MemberCheckOutTool(
    $input: UpdateMachineInput!
    $condition: ModelMachineConditionInput
  ) {
    updateMachine(input: $input, condition: $condition) {
      id
      currentHolderId
      status
    }
  }
`;

export const createTransaction = /* GraphQL */ `
  mutation createTransaction(
    $input: CreateTransactionInput!
    $condition: ModelTransactionConditionInput
  ) {
    createTransaction(input: $input, condition: $condition) {
      id
      transactionStatus
    }
  }
`;

export const createNewTool = /* GraphQL */ `
  mutation CreateNewTool(
    $input: CreateToolInput!
    $condition: ModelToolConditionInput
  ) {
    createTool(input: $input, condition: $condition) {
      id
      name
      description
    }
  }
`;

export const updateMemberCheckInStatus = /* GraphQL */ `
  mutation CheckInMember(
    $input: UpdateMemberInput!
    $condition: ModelMemberConditionInput
  ) {
    updateMember(input: $input, condition: $condition) {
      eid
      firstName
      lastName
      profilePicture {
        bucket
        key
        region
        url
        uploadUrl
      }
      phone
      checkInStatus
      email
      conductStatus
      trainingList {
        name
        level
        updatedAt
      }
      owner
    }
  }
`;
