/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * File: MemberDialog.js
 * Description: Pop up dialog containing all member information.
 * Used by staff to edit trainings, view checked out tools, see
 * upcoming reservations, and audit recent member actions.
 *
 * This same dialog will also be used by the member to view and
 * edit their own information. In the future, contexts will need
 * to be used to see if the current user is staff to allow editing
 * of trainings.
 *
 * written for Texas Inventionworks at UT Austin
 * authors: Davin Lawrence
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Button,
  ButtonGroup,
  CircularProgress,
  Box,
  Grid,
  DialogActions,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";

// API tings
import { API } from "aws-amplify";
import { getMemberProfile, userSync } from "api/queries";
import { useEffect, useState } from "react";
import Header from "../Header";
import MemberTrainings from "./components/MemberTrainings";

const useStyles = makeStyles((theme) => ({
  root: {},
  avatarContainer: {
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    [theme.breakpoints.down("sm")]: {
      paddingBottom: theme.spacing(2),
    },
  },
  titleContent: {
    [theme.breakpoints.up("md")]: {
      paddingTop: theme.spacing(1),
    },
    alignItems: "center",
  },
  avatar: {
    [theme.breakpoints.down("xs")]: {
      height: theme.spacing(8),
      width: theme.spacing(8),
    },
    [theme.breakpoints.up("sm")]: {
      height: theme.spacing(10),
      width: theme.spacing(10),
    },
    [theme.breakpoints.up("md")]: {
      height: theme.spacing(12),
      width: theme.spacing(12),
    },
  },
  memberContent: {},
  table: {
    height: "18em",
    width: "95%",
    paddingLeft: "2.5%",
    paddingRight: "2.5%",
    paddingBottom: "1em",
    overflow: "scroll",
  },
  tableCell: {
    borderBottomColor: theme.palette.divider,
  },
  tableHeader: {
    backgroundColor: theme.palette.background.paper,
  },
  recentHistory: {
    width: "100%",
  },
}));

async function getMemberDetails(member) {
  if (member === "") {
    return member;
  }
  const request = {
    query: getMemberProfile,
    variables: {
      eid: member,
    },
  };
  let response = await API.graphql(request);
  return response.data.getMember;
}

async function syncUser(member) {
  const request = {
    query: userSync,
    variables: {
      eid: member,
    },
  };

  let response = await API.graphql(request);
  return response;
}

MemberDialog.propTypes = {
  member: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

function MemberDialogContent(props) {
  const { classes, member, trainings, setTrainings, loading, dirty, setDirty } =
    props;

  if (loading) {
    return (
      <div align="center">
        <CircularProgress size={64} />
      </div>
    );
  } else if (!member) {
    return (
      <div>
        <Typography variant="body2">
          The specified user cannot be found
        </Typography>
      </div>
    );
  } else {
    return (
      <div>
        <Box className={classes.MemberContent}>
          <Box pl={2} pr={2} pb={2}>
            <Grid container spacing={2}>
              <Grid item xs={10} md={8} className={classes.titleContent}>
                <Header
                  variant="h3"
                  p={0}
                  text={`${member.firstName} ${member.lastName}`}
                />
                <Header variant="h5" pt={0} pb={0} text={`${member.eid}`} />
              </Grid>
            </Grid>
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <MemberTrainings
                classes={classes}
                trainings={trainings}
                setTrainings={setTrainings}
                dirty={dirty}
                setDirty={setDirty}
              />
            </Grid>
          </Grid>
        </Box>
      </div>
    );
  }
}

export default function MemberDialog(props) {
  const { member, onClose, open } = props;
  const [memberDetails, setMemberDetails] = useState({});
  const [validMember, setValidMember] = useState(false);
  const [trainings, setTrainings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [reload, setReload] = useState(false); // Used to force a reload of the member details

  // The next two variables allow us to only push changes to the API
  // that need to be changed. If adding more items to the Member Profile,
  // be sure to manage the dirty object.
  const initDirty = {
    trainings: false,
    profile: false,
  };

  const [dirty, setDirty] = useState({ ...initDirty });
  const classes = useStyles();

  const handleClose = () => {
    setMemberDetails({});
    setLoading(true);
    setValidMember(false);
    setDirty({ ...initDirty });
    onClose();
  };

  // Handle Trainings depends on the dirty object to know what it should
  // update.
  const handleUpdate = () => {
    console.log("Update button clicked, lol!");
    setLoading(true);
    if (member === "") {
      setLoading(false);
      return;
    }
    syncUser(member)
      .then((result) => {
        setReload(true);
      })
      .catch((err) => {
        console.log("Error syncing user: ", err);
        setLoading(false);
      });
  };

  useEffect(() => {
    setLoading(true);
    if (member === "") {
      setMemberDetails({});
      setLoading(false);
      setValidMember(false);
      return;
    }
    getMemberDetails(member)
      .then((result) => {
        setMemberDetails(result);
        setTrainings(result.trainings.items);
        setLoading(false);
        if (result !== "") {
          setValidMember(true);
        }
      })
      .catch((err) => {
        console.log(err);
        setMemberDetails(false);
        setLoading(false);
        setValidMember(false);
      });
  }, [member]);

  useEffect(() => {
    if (!reload) {
      return;
    }
    setLoading(true);
    if (member === "") {
      setLoading(false);
      return;
    }
    getMemberDetails(member)
      .then((result) => {
        setMemberDetails(result);
        setTrainings(result.trainings.items);
        setLoading(false);
        setReload(false);
        if (result !== "") {
          setValidMember(true);
        }
      })
      .catch((err) => {
        console.log(err);
        setMemberDetails(false);
        setLoading(false);
        setReload(false);
        setValidMember(false);
      });
  }, [reload]);

  return (
    <Dialog
      className={classes.root}
      maxWidth="md"
      fullWidth={true}
      onClose={handleClose}
      aria-labelledby="member-dialog"
      open={open}
      scroll="paper"
    >
      <DialogTitle>
        <Header variant="h4" p={0} text="Member Details" />
      </DialogTitle>
      <DialogContent>
        <MemberDialogContent
          classes={classes}
          loading={loading}
          member={memberDetails}
          setMember={setMemberDetails}
          trainings={trainings}
          setTrainings={setTrainings}
          dirty={dirty}
          setDirty={setDirty}
        />
      </DialogContent>
      <DialogActions>
        <Box p={2} pt={4}>
          <ButtonGroup color="primary" variant="contained">
            <Button disabled={loading || !validMember} onClick={handleUpdate}>
              Refresh Trainings
            </Button>
            <Button onClick={handleClose}>Close</Button>
          </ButtonGroup>
        </Box>
      </DialogActions>
    </Dialog>
  );
}
