import { useContext, useState } from "react";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Paper,
  makeStyles,
  Box,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
} from "@material-ui/core";
import { Header } from "components";
import { createNewTool } from "api/mutations";
import { API } from "aws-amplify";
import AppContext from "helpers/AppContext";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  formControl: {
    margin: theme.spacing(2),
  },
  description: {
    minWidth: "100%",
  },
}));

async function createTool(newTool) {
  let request = {
    query: createNewTool,
    variables: {
      input: {
        id: newTool.id,
        name: newTool.name,
        description: newTool.description.trim(),
        toolType: newTool.toolType,
        currentHolderId: "tiw",
        currentWorkstationId: "none",
        toolStatus: "CHECKEDIN",
      },
    },
  };

  let response = await API.graphql(request);
  return response.data.createTool;
}

const TOOL_TYPES = ["HANDHELD", "WORKSTATION", "PRINTER", "POWERTOOL"];

export default function NewToolDialog(props) {
  const initErrors = {
    id: false,
    idText: "",
    name: false,
    nameText: "",
  };

  const initState = {
    id: "",
    name: "",
    toolType: "HANDHELD",
    description: "",
  };
  const { open, onClose } = props;
  const classes = useStyles();
  const [newTool, setNewTool] = useState({ ...initState });
  const [formErrors, setFormErrors] = useState({ ...initErrors });
  const { setErrState } = useContext(AppContext);

  const handleClose = () => {
    setNewTool({ ...initState });
    setFormErrors({ ...initErrors });
    onClose();
  };

  const handleChange = (event) => {
    const name = event.target.name;
    let value;
    if (name === "id") {
      value = event.target.value.toUpperCase().trim();
    } else if (name === "toolType") {
      value = TOOL_TYPES[event.target.value];
    } else {
      value = event.target.value;
    }
    setNewTool({
      ...newTool,
      [name]: value,
    });
  };

  const validateForm = () => {
    let valid = true;
    let errors = { ...initErrors };
    if (newTool.id === "") {
      errors.id = true;
      errors.idText = "Cannot Be Empty";
      valid = false;
    }
    if (newTool.name === "") {
      errors.name = true;
      errors.nameText = "Cannot Be Empty";
      valid = false;
    }
    setFormErrors({ ...errors });
    return valid;
  };

  const handleSubmit = () => {
    if (validateForm()) {
      createTool(newTool)
        .then((result) => {
          setErrState({
            severity: "success",
            open: true,
            text: `Successfully Created Tool: ${newTool.id}`,
          });
          setNewTool({ ...initState });
        })
        .catch((err) => {
          console.warn(err);
          setFormErrors({
            ...formErrors,
            id: true,
            idText: "Must be Unique",
          });
        });
    }
  };

  return (
    <Dialog onClose={onClose} open={open} maxWidth="sm" fullWidth>
      <DialogTitle>
        <Header p={0} variant="h4" text="Add New Tool" />
      </DialogTitle>
      <DialogContent>
        <Paper>
          <Box p={2}>
            <form className={classes.root} autocomplete="off">
              <TextField
                id="new-tool-id"
                label="Tool ID"
                value={newTool.id}
                inputProps={{
                  name: "id",
                  style: {
                    textTransform: "uppercase",
                  },
                }}
                onInput={handleChange}
                required
                helperText={formErrors.idText}
                error={formErrors.id}
              />
              <TextField
                id="new-tool-name"
                label="Tool Name"
                value={newTool.name}
                inputProps={{
                  name: "name",
                }}
                onInput={handleChange}
                required
                error={formErrors.name}
                helperText={formErrors.nameText}
              />
              <FormControl className={classes.toolType}>
                <InputLabel id="tool-type">Tool Type</InputLabel>
                <Select
                  labelId="tool-type"
                  id="tool-type"
                  value={TOOL_TYPES.indexOf(newTool.toolType) ?? 0}
                  defaultValue={0}
                  inputProps={{
                    name: "toolType",
                  }}
                  onChange={handleChange}
                >
                  {TOOL_TYPES.map((type, idx) => {
                    return (
                      <MenuItem value={idx} key={`tool-type-selection-${idx}`}>
                        {type}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              <br />
              <TextField
                id="new-tool-description"
                label="Description"
                value={newTool.description}
                className={classes.description}
                inputProps={{
                  name: "description",
                  maxLength: 250,
                }}
                multiline
                rows={4}
                onInput={handleChange}
              />
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
              >
                Create New Tool
              </Button>
            </form>
          </Box>
        </Paper>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={handleClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
