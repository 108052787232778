import { API } from "aws-amplify";
import { updateToolHolderId } from "api/mutations";
import { getMemberProfile, getToolStatus } from "api/queries";
import { checkOutToolTransaction } from "./transactions";

export default async function checkOutTool(staffMemberId, eid, toolId) {
  const toolStatusRequest = {
    query: getToolStatus,
    variables: {
      id: toolId.toUpperCase(),
    },
  };

  const checkOutToolRequest = {
    query: updateToolHolderId,
    variables: {
      input: {
        id: toolId.toUpperCase(),
        currentHolderId: eid,
        status: "CHECKEDOUT",
      },
    },
  };

  const validateMemberRequest = {
    query: getMemberProfile,
    variables: {
      eid: eid,
    },
  };

  let response = await API.graphql(toolStatusRequest);
  let status;
  try {
    status = response.data.getMachine.status;
  } catch (err) {
    throw new Error("Not a valid Tool ID");
  }

  response = await API.graphql(validateMemberRequest);
  let member = response.data.getMember;

  if (status === "CHECKEDOUT") {
    // tool already checked out
    throw new Error("Tool is already checked out!");
  } else if (!member) {
    throw new Error("Invalid eid");
  } else {
    response = await API.graphql(checkOutToolRequest);
    checkOutToolTransaction(eid, staffMemberId, toolId);
    return response.data.updateTool;
  }
}
