import React, { useState, useContext } from "react";
import { Redirect } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";

import { Grid, TextField, Button, Box } from "@material-ui/core";

import { AppContext, signIn } from "helpers";

const useStyles = makeStyles((theme) => ({
  login: {
    padding: "20px",
    backgroundColor: theme.palette.background.paper,
  },
  logo: {
    alignContent: "center",
    backgroundColor: "#fff",
  },
  button: {
    borderRadius: 0,
    marginBottom: 20,
    width: "100%",
    fontSize: 16,
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginBottom: -12,
  },
  accordionTitle: {
    display: "flex",
    fontSize: 16,
    justifyContent: "center",
    textTransform: "uppercase",
    backgroundColor: theme.palette.primary.main,
  },
  accordianContent: {
    justifyContent: "center",
  },
  accordion: {
    width: "100%",
    marginBottom: 20,
    borderWidth: 0,
    justifyContent: "center",
    boxShadow: "none",
  },
  form: {
    padding: 0,
    justifyContent: "center",
    "& > *": {
      width: "100%",
    },
  },
}));

function Login(props) {
  const classes = useStyles();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const { authenticated, setErrState, setAuthenticated } =
    useContext(AppContext);

  const handleSubmit = (e) => {
    setLoading(true);
    e.preventDefault();
    let credentials = {
      user: username,
      pass: password,
    };

    signIn(credentials)
      .then((result) => {
        setAuthenticated(true);
      })
      .catch((err) => {
        setErrState({
          severity: "error",
          open: true,
          text: "Could not log in, check username and password",
        });
        setTimeout(() => setLoading(false), 1200);
      });
  };
  if (authenticated) {
    return <Redirect to="/dashboard" />;
  } else {
    return (
      <Grid container maxWidth="sm" justifyContent="center">
        <Grid item xs={12} sm={7}>
          <Box className={classes.logo} p={2}>
            <img src="./TX-invent-logo-01.svg" alt="Texas Inventionworks" />
          </Box>
        </Grid>
        <Grid item xs={12} sm={7}>
          <Box className={classes.login} p={2}>
            <form
              noValidate
              autoComplete="off"
              className={classes.form}
              onSubmit={handleSubmit}
            >
              <TextField
                id="username"
                inputProps={{
                  color: "#D6D2C4",
                }}
                label="User Name"
                value={username}
                fullWidth
                disabled={loading}
                margin="normal"
                variant="outlined"
                onInput={(e) => setUsername(e.target.value)}
              />
              <TextField
                id="password"
                className={classes.textField}
                label="Password"
                value={password}
                fullWidth
                disabled={loading}
                margin="normal"
                variant="outlined"
                type="password"
                onInput={(e) => setPassword(e.target.value)}
              />
              <Button
                type="submit"
                disabled={loading}
                size="large"
                variant="contained"
                color="primary"
              >
                submit
              </Button>
            </form>
          </Box>
        </Grid>
      </Grid>
    );
  }
}

export default Login;
