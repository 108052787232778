import checkInTool from "./checkInTool";

export default async function checkInTools(tools) {
  for (let i in tools) {
    console.log(tools[i]);
    try {
      checkInTool("tiw", tools[i]);
    } catch (err) {
      console.log(err);
      return false;
    }
  }
  return true;
}
