import { createTheme, responsiveFontSizes } from "@material-ui/core/styles";

// Set Up Theme
let theme = createTheme({
  palette: {
    background: {
      default: "#2D2F2E",
      paper: "#333f48",
    },
    text: {
      primary: "#fff",
      secondary: "#D6D2C4",
    },
    primary: {
    // was bf5700 (orange) -> changed to make sure staff aren't confused
      main: "#005f86",  // blue
    },
    secondary: {
      main: "#FFD600",
    },
    action: {
      hover: "#bf5700",
      selected: "#bf5700",
    },
    divider: "#A29B96",
  },
  paper: {
    padding: 20,
  },
  typography: {
    body2: {
      fontSize: 16,
    },
    h1: {
      fontFamily: "'Barlow Condensed', sans-serif",
      fontWeight: 600,
    },
    h2: {
      fontFamily: "'Barlow Condensed', sans-serif",
      fontWeight: 600,
    },
    h3: {
      fontFamily: "'Barlow Condensed', sans-serif",
      fontWeight: 600,
    },
    h4: {
      fontFamily: "'Barlow Condensed', sans-serif",
      fontWeight: 600,
    },
    h5: {
      fontFamily: "'Barlow Condensed', sans-serif",
      fontWeight: 600,
    },
    h6: {
      fontFamily: "'Barlow Condensed', sans-serif",
      fontSize: 18,
      fontWeight: 600,
    },
  },
});

theme = responsiveFontSizes(theme);
export default theme;
