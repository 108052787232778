/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * File: Header.js
 * Description: Create a simplified <Header /> tag that con-
 * tains appropriate padding.
 *
 * Header requires two props to passed in the tag. Variant can
 * be any valid html header tag. The text prop must be the
 * text that will be in the header. An example call would look
 * like:
 *
 * <Header variant='h3' text='Texas Inventionworks' />
 *
 * Any other props will be passed into the Material UI <Box>
 * element. This can be used to change the margin and padding
 * of the Header, which is useful when two headers are present
 * on adjacent lines or when there is a subtitle.
 *
 * written for Texas Inventionworks at UT Austin
 * authors: Davin Lawrence
 *
 * TRANS RIGHTS ARE HUMAN RIGHTS
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { Typography, Box } from "@material-ui/core";

import PropTypes from "prop-types";

const defaultPadding = 2;

export default function Header(props) {
  const { text, variant, ...otherProps } = props;
  return (
    <Box p={defaultPadding} {...otherProps}>
      <Typography variant={variant}>{text}</Typography>
    </Box>
  );
}

Header.propTypes = {
  text: PropTypes.string.isRequired,
  variant: PropTypes.string.isRequired,
};
