/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * File: PageHeader.js
 * Description: Simple module to create the Page header. The
 * only required prop is text which contains the text of the
 * header.
 *
 * NOTE: THIS MUST BE PLACED INSIDE OF A GRID CONTAINER OR IT
 * WILL NOT FUNCTION AS INTENDED. An example call of this mod-
 * ule looks like:
 <Grid container >
    <PageHeader text='Staff Dashboard' />
    { ... other Grid items that set the layout of the page }
 </Grid>
 *
 * written for Texas Inventionworks at UT Austin
 * authors: Davin Lawrence
 * 
 * TRANS RIGHTS ARE HUMAN RIGHTS
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { Typography, Grid, Box } from "@material-ui/core";
import PropTypes from "prop-types";

export default function PageHeader(props) {
  const { text } = props;
  return (
    <Grid item xs={12}>
      <Box p={2}>
        <Typography variant="h2">{text}</Typography>
      </Box>
    </Grid>
  );
}

PageHeader.propTypes = {
  text: PropTypes.string.isRequired,
};
