/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * File: PaperAccent.js
 * Description: Creates a nice accent at the top of a paper
 * item. Optional prop, loading, causes the bar to flash when
 * content of the Paper item is loading. Example use of this
 * module is as follows:
 *
 * <Paper>
 *      <PaperAccent loading={true} />
 * </Paper>
 *
 * For a solid bar, simply:
 * <Paper>
 *      <PaperAccent />
 * </Paper>
 *
 * written for Texas Inventionworks at UT Austin
 * authors: Davin Lawrence
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import {
    Divider,
    LinearProgress
} from '@material-ui/core';
import {
    makeStyles
} from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
    accent: {
        backgroundColor: theme.palette.primary.main,
        width: "75%",
        height: "0.25em",
    },
    loading: {
        width: "75%",
        height: "0.25em",
    }
}))

export default function PaperAccent(props) {
    const classes = useStyles();
    const { loading } = props;

    return (
        <div align="center">
            {loading && <LinearProgress className={classes.loading} />}
            {!loading && <Divider className={classes.accent} />}
        </div>
    )
}