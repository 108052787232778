import { getToolIdByName } from "api/queries";
import { API } from "aws-amplify";

export default async function getToolId(toolName) {
  let request = {
    query: getToolIdByName,
    variables: {
      name: toolName,
    },
  };

  let response = await API.graphql(request);
  let items = response.data.listMachinesByName.items;
  if (items.length == 1) {
    return items[0].id;
  } else {
    throw new Error("No tool with that name found");
  }
}
