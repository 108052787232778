/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * File: StaffDashboard.js
 * Route: https://texasinventionworks.com/staff/dashboard
 * Description: Recreation of the classic TIW staff dashboard
 * Contains simple member lookup, tool checkout, list of rec-
 * ently submitted prints, and currently checked in members.
 *
 * written for Texas Inventionworks at UT Austin
 * authors: Davin Lawrence
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { useState } from "react";
import { Grid } from "@material-ui/core";

import {
  // Requests,
  CheckedInMembers,
  MemberSearch,
  ToolCheckout,
  ToolCheckin,
} from "components/Dashboard";

import {
  PageHeader,
  MemberDialog,
  // ManagePrintDialog,
  // NewPrintDialog,
} from "components";

export default function StaffDashboard() {
  const [memberDialog, setMemberDialog] = useState(false);
  const [searchMember, setSearchMember] = useState("");
  // const [managePrintDialog, setManagePrintDialog] = useState(false);
  // const [newPrintDialog, setNewPrintDialog] = useState(false);
  // const [searchPrint, setSearchPrint] = useState("");

  const handleMemberDialogClose = () => {
    setSearchMember("");
    setMemberDialog(false);
  };

  // const handlePrintClose = () => {
  //   setSearchPrint("");
  //   setManagePrintDialog(false);
  //   setNewPrintDialog(false);
  // };

  return (
    <Grid container spacing={2}>
      <PageHeader text="Staff Dashboard" />
      <Grid item xs={12} sm={4}>
        <MemberSearch
          setMemberDialog={setMemberDialog}
          setSearchMember={setSearchMember}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <ToolCheckout />
      </Grid>
      <Grid item xs={12} sm={4}>
        <ToolCheckin />
      </Grid>
      {/* <Grid item xs={12}>
        <Requests
          setManagePrintDialog={setManagePrintDialog}
          setNewPrintDialog={setNewPrintDialog}
          setSearchPrint={setSearchPrint}
        />
      </Grid> */}
      <Grid item xs={12}>
        {/* <CheckedInMembers
          setMemberDialog={setMemberDialog}
          setSearchMember={setSearchMember}
        /> */}
      </Grid>
      <MemberDialog
        open={memberDialog}
        onClose={handleMemberDialogClose}
        member={searchMember}
      />
      {/* <ManagePrintDialog
        open={managePrintDialog}
        onClose={handlePrintClose}
        printId={searchPrint}
      />
      <NewPrintDialog open={newPrintDialog} onClose={handlePrintClose} /> */}
    </Grid>
  );
}
